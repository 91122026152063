<template>
  <div>
    <div></div>
    <header-style title="Project Details"> </header-style>
    <div>

    <div class="">
  <b-card class="p-3 m-3">
    <h5 style="font-size:20px;font-weight: 700;" class="mb-2">DAFA Payment (DPA)</h5>
    <b-card-text style="color:#808693;font-size:16px; font-weight:600;line-height: 20px;margin-inline-end: 260px;">
      A completely unified checkout and payments integration, DPA lets you truly consolidate your payments stack,
      plus add APIs and tools
      from across the web to build dynamic end-to-end payment flows for the first time. </b-card-text>
    <div class="details-user pt-3">
      <img src="../../assets/images/user-img.png" alt="" style="width:48px;height:48px">
      <div class="">
        <h6 style="color:#161A22;font-size:16px; font-weight:600">Mohamed Gamal</h6>
        <span style="color:#808693;font-size:13px; font-weight:500">Product Manager</span>
      </div>
    </div>
  </b-card>
  </div>
  <div class="m-3">
    <b-card class="p-3">
      <h5 style="font-size:20px;font-weight: 700;">payment Gateway List</h5>
    </b-card>
    <div class="d-flex justify-content-between m-2">
      <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
        <span style="color:#808693;font-size:14px;font-weight:700">Payment Gateway</span>
      </b-form-checkbox>
      <span class="mx-5">Show</span>
    </div>
    <b-card class="p-2">
      <div class="d-flex justify-content-between pt-2">
        <b-form-checkbox id="checkbox-2" v-model="status" name="checkbox-2" value="accepted"
          unchecked-value="not_accepted" class="">
          <img src="../../assets/images/hyperPay.png" alt=""
            style="width:50px;height: 50px; border-radius: 50%;  margin-inline-end: 5px;">
          <span style="color:#161A22;font-size:14px; font-weight:600" class="">HyperPay</span>
        </b-form-checkbox>
        <label class="switch mx-4">
          <input type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
      <hr>
      <div class="d-flex justify-content-between ">
        <b-form-checkbox id="checkbox-3" v-model="status" name="checkbox-3" value="accepted"
          unchecked-value="not_accepted">
          <img src="../../assets/images/noonPay.png" alt=""
            style="width:45px;height: 45px; border-radius: 50%; margin-inline-end: 5px;">
            <span style="color:#161A22;font-size:14px; font-weight:600" class="mx-1">Noon Pay</span>
        </b-form-checkbox>
        <label class="switch mx-4">
          <input type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
      <hr>
      <div class="d-flex justify-content-between pb-2">
        <b-form-checkbox id="checkbox-4" v-model="status" name="checkbox-4" value="accepted"
          unchecked-value="not_accepted" class="">
          <img src="../../assets/images/fawrypay.png" alt=""
            style="width:45px;height: 45px; border-radius: 50%;  margin-inline-end: 5px;" class="">
            <span style="color:#161A22;font-size:14px; font-weight:600" class="mx-1">Fawry</span>
        </b-form-checkbox>
        <label class="switch mx-4">
          <input type="checkbox">
          <span class="slider round"></span>
        </label>
      </div>
    </b-card>
</div>

</div>
  </div>
</template>
<script>
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  components: { HeaderStyle },
  name: 'projectDetails'
}
</script>
<style>
.details-user{
display: flex;
gap: 9px;
}
</style>
